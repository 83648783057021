<template>
  <div>
  	<damo-header/>
	<div class="banner-box">
		<div class="box">
			<div class="solution-banner">
				<p class="p01 fw">解决痛点，赋能前行</p>
				<p class="p02">助力企业实现数字化转型</p>
			</div>
		</div>
		<div class="banner-con">
			<img src="../static/images/banner_04.gif">
		</div>
	</div>
	<div class="box solution-a section">
		<div class="solution-title fw">解决方案</div>
		<div class="solution-acon">
			<div class="con-l">
				<div class="con-l-a con-l-bg">
					<p class="p01 fw">01</p>
					<p class="p02">行业痛点</p>
					<p class="p03" style="margin-bottom: 20px;">线上红利消失，线下客流减少，获客成本增加；线上线下渠道难以整合，会员信息收集困难、管理难以同步。</p>
					<p class="p02">解决方案</p>
					<p class="p03">全域流量整合，构建企业私域流量池。通过系统工具，建立完善的会员增长链路，实现会员的全域触达。</p>
					<div class="con-l-b">
						<img src="../static/images/sicon01.png">
						<p class="p-line pr">流量</p>
						<img src="../static/images/sicon02.png">
						<p>获客</p>
					</div>
				</div>
				<div class="con-l-a">
					<p class="p01 fw">03</p>
					<p class="p02">行业痛点</p>
					<p class="p03" style="margin-bottom: 20px;">企业管理成本高，上级信息下达效率低，信息损耗大；培训成本高、效果甚微；单独业务单独系统，业务协作难。</p>
					<p class="p02">解决方案</p>
					<p class="p03">各项指标数据化、门店任务自动化、商品信息在线化，建立规范的数字化企业管理体系，帮助员工提高工作效率，增强自主学习能力，节省企业经营成本。</p>
					<div class="con-l-b">
						<img src="../static/images/sicon05.png">
						<p class="p-line pr">管理</p>
						<img src="../static/images/sicon06.png">
						<p>提效</p>
					</div>
				</div>
			</div>
			<div class="con-r">
				<div class="con-r-a">
					<p class="p01 fw">02</p>
					<p class="p02">行业痛点</p>
					<p class="p03" style="margin-bottom: 20px;">门店服务质量没有直观的体现，只有暴露了问题才能解决问题；导购服务水平难以量化，顾客反馈无法及时、有效传达，会员流失严重。</p>
					<p class="p02">解决方案</p>
					<p class="p03">零售终端智能办公系统提供实时数据分析和服务数字化，便于员工及早发现问题、解决问题，全面改善客户服务，提高消费体验。</p>
					<div class="con-l-b">
						<img src="../static/images/sicon03.png">
						<p class="p-line pr">服务</p>
						<img src="../static/images/sicon04.png">
						<p>体验</p>
					</div>
				</div>
				<div class="con-r-a con-r-bg">
					<p class="p01 fw">04</p>
					<p class="p02">行业痛点</p>
					<p class="p03" style="margin-bottom: 20px;">数据整合能力弱，对客户全方位信息数据等掌握甚少，无法深入洞察、挖掘客户价值，营销信息难以有效触达，转化率低，面临会员沉睡和流失。</p>
					<p class="p02">解决方案</p>
					<p class="p03">整合企业一方数据建立企业的DMP，通过营销工具及营销插件，实现千人千面的精准营销，挖掘会员生命周期价值，唤醒沉睡会员，提升复购率。</p>
					<div class="con-l-b">
						<img src="../static/images/sicon07.png">
						<p class="p-line pr">存量</p>
						<img src="../static/images/sicon08.png">
						<p>转化</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="box">
		<div class="solution-title fw" style="width: 182px;">部分客户案例</div>
		<div class="tab-case-box">
			<ul class="tab-case-ul">
				<li :class="item.type === type ? 'on': ''" v-for="(item, index) in caseTitle" :key="index" @click="handleTab(item.type)">{{ item.name }}</li>
			</ul>
		</div>
		<div class="case-con-div">
			<div v-on:mouseover="changeActive($event, index)" v-on:mouseout="removeActive($event)" v-for="(item, index) in caseListon" :key="index" v-if="index < maxNum" class="case-con">
				<img class="case-con-img" :src="item.img">
				<div class="case-con-text">
					<p class="p01">{{ item.title }}</p>
					<p v-if="indexnum != index" class="p02" v-html="item.desc"></p>
					<p v-if="indexnum == index" class="p02" v-html="item.info"></p>
				</div>
			</div>
		</div>
		<div v-show="caseListon.length>20&&showNum" class="more-div" @click="handleNum">查看更多案例</div>
	</div>
  	<damo-footer @footclick="handleFoot"/>
  </div>
</template>
<script>
import DamoHeader from '@/components/DamoHeader.vue'
import DamoFooter from '@/components/DamoFooter.vue'
export default {
	name: 'solution',
	metaInfo: {
		title: '达摩网络-让信息连接一切，成就所有零售者',
		link: [
			{ rel: 'protocol', href: 'https://ding-doc.dingtalk.com/doc#/faquestions/ftpfeu' }
		]
	},
	components: {
		DamoHeader,
		DamoFooter
	},
	data() {
		return {
			maxNum: 20,
			selectId: null,
			showNum: true,
			isfixTab: false,
			indexnum: 'all',
			type: 'ca',
			caseTitle: [{
				type: 'ca',
				name: '全部'
			}, {
				type: 'cb',
				name: '集团'
			}, {
				type: 'cc',
				name: '男装'
			}, {
				type: 'cd',
				name: '女装'
			}, {
				type: 'ce',
				name: '设计师'
			}, {
				type: 'cf',
				name: '童装'
			}, {
				type: 'cg',
				name: '其他'
			}],
			caseListon: [],
			allcaseList: [{
				type: 'cb',
				title: '江南布衣集团',
				img: require('../static/images/case/jnby.png'),
				desc: '　　旗下品牌JNBY/速写/jnby by JNBY/less/POMME DE TERRE蓬马/JNBYHOME/SAMO/REVERB/LASU MIN...',
				info: '　　旗下品牌JNBY/速写/jnby by JNBY/less/POMME DE TERRE蓬马/JNBYHOME/SAMO/REVERB/LASU MIN SOLA/A PERSONAL NOTE 73 <br>　　江南布衣集团由中国设计师李琳创立于 1994年，并于2016年10月在香港正式上市，总部位于中国杭州。集团以设计作为导向，以多品牌模式及全渠道互动平台在线上及线下进行产品的销售和推广。截止至目前，在中国及17个其他国家及地区的超过1900家零售门店。'
			}, {
				type: 'cb',
				title: '中哲慕尚集团',
				img: require('../static/images/case/gxg.png'),
				desc: '　　旗下品牌 GXG/gxg jeans/gxg.kids/Yatlas/2XU 慕尚集团是一家专注于中国时尚男装市场的公司，创办于2007年...',
				info: '　　旗下品牌 GXG/gxg jeans/gxg.kids/Yatlas/2XU <br>　　慕尚集团是一家专注于中国时尚男装市场的公司，创办于2007年，旗下品牌门店覆盖全国32个省市自治区，门店总数达到2000多家，2017年度品牌总销售额达到105亿。 '
			}, {
				type: 'cb',
				title: '欣贺集团',
				img: require('../static/images/case/hx.png'),
				desc: '　　旗下品牌JORYA/JORYAWEEKEND/GIVHSHYH巨式国际/ANMANI恩曼琳/CAROLINE卡洛琳/AIVEI艾薇欣贺股份是...',
				info: '　　旗下品牌JORYA/JORYAWEEKEND/GIVHSHYH巨式国际/ANMANI恩曼琳/CAROLINE卡洛琳/AIVEI<br>　　艾薇欣贺股份是一家国际一级流行时尚女装设计、生产与销售一体的国内知名女装企业。经过多年精心的市场开拓与品牌推广，公司现今除厦门总部外，分支机构遍布于国内外一线城市，并于国内众多知名高档商场开设有近700家专卖店和旗舰店。'
			}, {
				type: 'cb',
				title: '马克华菲集团',
				img: require('../static/images/case/mkhf.png'),
				desc: '　　旗下品牌FAIRWHALE女装/ FAIRWHALE男装/ Reshake/ DEBRAND/ CAMEL ACTIVE/ SPACE5 马克华菲品牌...',
				info: '　　旗下品牌FAIRWHALE女装/ FAIRWHALE男装/ Reshake/ DEBRAND/ CAMEL ACTIVE/ SPACE5 <br>　　马克华菲品牌诞生于2000年，公司在十年多的时间里开设专卖店百余家，遍及国内各大中城市，产品亦日趋多样化，目前已涵盖国际时尚男装、马克华菲男女装、鞋、包、皮具、香水及配饰等多个领域。核心客群是那些热爱时尚和休闲的都市高级白领及追求高品质生活的中上阶层。'
			}, {
				type: 'cb',
				title: '红纺文化',
				img: require('../static/images/case/hf.png'),
				desc: '　　旗下品牌paul frank/ pancoat/ the Simpsons/ tokidoki/ Altips/ SESAME STREET/ RE...',
				info: '　　旗下品牌paul frank/ pancoat/ the Simpsons/ tokidoki/ Altips/ SESAME STREET/ RE集合店<br>　　红纺文化是一家IP商业转化集团。以IP运营为核心，搭建内容、商品、营销、媒体、渠道为产业链的品牌文化投资管理集团,致力成为IP商业转化佼佼者。秉承“红纺中国、美利天下”的企业理念，经历数次产业转型，站在行业前端，在中国开辟了潮流版块市场。'
			}, {
				type: 'cb',
				title: '美特斯邦威',
				img: require('../static/images/case/mb.png'),
				desc: '　　上海美特斯邦威服饰股份有限公司始创于1995年，目前旗下拥有“Metersbonwe”和“ME&CITY”两大时尚休闲品牌...',
				info: '　　上海美特斯邦威服饰股份有限公司始创于1995年，目前旗下拥有“Metersbonwe”和“ME&CITY”两大时尚休闲品牌；“米喜迪”和“MooMoo”两大童装品牌，慢生活倡导品牌“CH’IN褀”。作为中国服装产业代表企业之一，浸润时尚产业20余年，秉承“世界的裁缝”的梦想，以“不走寻常路”的精神不断进行企业的发展创新和向上突破。'
			}, {
				type: 'cc',
				title: '沙驰',
				img: require('../static/images/case/shachi.png'),
				desc: '　　Satchi是欧洲高端商务男装品牌，也是全球CEO峰会唯一指定男装品牌。自上世纪八十年代进入亚洲，以其...',
				info: '　　Satchi是欧洲高端商务男装品牌，也是全球CEO峰会唯一指定男装品牌。自上世纪八十年代进入亚洲，以其卓越品质迅速扩展，于全国400多家顶级商场开设品牌专柜；为各界成功男士带来欧洲经典着装和箱包的新体验。'
			}, {
				type: 'cc',
				title: '哈吉斯',
				img: require('../static/images/case/hajs.png'),
				desc: '　　一线休闲男装HAZZYS创立于2000年。综合了精心裁剪和当今国际流行的色彩，洋溢着浓郁的英伦风情，有着大...',
				info: '　　一线休闲男装HAZZYS创立于2000年。综合了精心裁剪和当今国际流行的色彩，洋溢着浓郁的英伦风情，有着大不列颠贵族家庭的高贵外表，保守、高雅的生活风尚中注入率真、自在、谐趣又不失奢华雅致的时尚元素，让HAZZYS一族体验英国贵族式的经典、奢华与尊贵，展现不凡的生活品位。'
			}, {
				type: 'cc',
				title: '柒牌',
				img: require('../static/images/case/qipai.png'),
				desc: '　　始创于1979年，集团拥有世界一流的服装生产设备，并在全国31个省、市（自治区）设立3000多家专卖店...',
				info: '　　始创于1979年，集团拥有世界一流的服装生产设备，并在全国31个省、市（自治区）设立3000多家专卖店。多年来产品销售收入、利润总额名列全国服装行业前十强。2019年，柒牌品牌价值达到615.78亿，连续15年入围“中国500最具价值品牌”榜。'
			}, {
				type: 'cc',
				title: '七匹狼',
				img: require('../static/images/case/sw.png'),
				desc: '　　七匹狼集团于1990年创立， 作为中国服装业首家上市公司，被誉为“中国休闲装之王”和“茄克专家”，其男装的...',
				info: '　　七匹狼集团于1990年创立， 作为中国服装业首家上市公司，被誉为“中国休闲装之王”和“茄克专家”，其男装的特许专卖销售网络在全国覆盖面积达90%以上，拥有专卖店铺4000多家。“七匹狼牌茄克衫”连续十年荣获同类产品市场占有率第一。'
			}, {
				type: 'cd',
				title: '红袖',
				img: require('../static/images/case/hongxiu.png'),
				desc: '　　中国十佳设计师刘华于1995年创办女装品牌红袖。红袖倡导品质、品味与知性的生活态度，不断在旅行中寻找文...',
				info: '　　中国十佳设计师刘华于1995年创办女装品牌红袖。红袖倡导品质、品味与知性的生活态度，不断在旅行中寻找文化艺术与设计生活的时尚灵感，以高品质与合理的价格提供触手可及的国际前沿时尚生活方式，成就女性无限的可能。2016年荣获“最佳青春女装品牌”称号，在全国各地拥有门店1600余家。'
			}, {
				type: 'cd',
				title: '玖姿',
				img: require('../static/images/case/jiuzi.png'),
				desc: '　　JZ玖姿为安正时尚集团股份有限公司旗下品牌，成立于2001年，致力于追求时尚、精致与实用美学的完...',
				info: '　　JZ玖姿为安正时尚集团股份有限公司旗下品牌，成立于2001年，致力于追求时尚、精致与实用美学的完美平衡。秉承“优雅、大气、经典、时尚”的设计理念，JZ玖姿运用优质上等的面料、精致的细节装饰、优雅的色彩与图案表现，演绎现代都市女性的自信、优雅、女人味。'
			}, {
				type: 'ce',
				title: 'D-HARRY',
				img: require('../static/images/case/dhappy.png'),
				desc: '　　D-HARRY（迪哈利）始创于1991年，源于意大利时尚品牌。迪哈利以西方复古为美学基因，同步国际时装...',
				info: '　　D-HARRY（迪哈利）始创于1991年，源于意大利时尚品牌。迪哈利以西方复古为美学基因，同步国际时装潮流趋势，将东方情怀与西方复古完美融合。通过经典、怀旧的款式以及对细节与剪裁的注重，展现欧洲贵族式的复古与高雅。'
			}, {
				type: 'ce',
				title: '达衣岩',
				img: require('../static/images/case/yanyida.png'),
				desc: '　　达衣岩是为艺术而生的中国设计师品牌，它坚持为热爱生活的都市知性女性创造有亲和力、有艺术温度的...',
				info: '　　达衣岩是为艺术而生的中国设计师品牌，它坚持为热爱生活的都市知性女性创造有亲和力、有艺术温度的服装。质地主要以天然原创棉为主，讲究舒适。'
			}, {
				type: 'cf',
				title: '巴拉巴拉',
				img: require('../static/images/case/balabala.png'),
				desc: '　　巴拉巴拉是森马服饰于2002年创立的具有国际视野的时尚大众童装品牌。产品全面覆盖0-16岁儿童的服装...',
				info: '　　巴拉巴拉是森马服饰于2002年创立的具有国际视野的时尚大众童装品牌。产品全面覆盖0-16岁儿童的服装、童鞋、配饰品类，以“童年不同样”的品牌理念，为孩子们提供既时尚又实用的儿童服饰产品，让孩子们享受美好自在的童年。服装销量连续数年排名第一，稳居行业龙头。'
			}, {
				type: 'cf',
				title: '马·拉丁',
				img: require('../static/images/case/malad.png'),
				desc: '　　原创设计师童装品牌，来自生活和大自然的想象力、温暖的插画绘本，设计师运用丰富自然的图形和印象，尊重...',
				info: '　　原创设计师童装品牌，来自生活和大自然的想象力、温暖的插画绘本，设计师运用丰富自然的图形和印象，尊重儿童的个体和特质，传递快乐，更注重孩子心灵的陪伴。'
			}, {
				type: 'cf',
				title: 'ROOKIE',
				img: require('../static/images/case/rookie.png'),
				desc: '　　“Rookie-China”隶属世界童装零售巨头美国“HADDAD”公司旗下。定位为运动的、时尚的和休闲的童装与青少年装...',
				info: '　　“Rookie-China”隶属世界童装零售巨头美国“HADDAD”公司旗下。定位为运动的、时尚的和休闲的童装与青少年装两大模块，涉及童装及青少年装各品类的细分市场，集合不同风格及不同品类的品牌与产品，这种全新的一站式购物体验，在国内童装界算是开创了先河。'
			}, {
				type: 'cg',
				title: '康奈',
				img: require('../static/images/case/kangnai.png'),
				desc: '　　康奈集团有限公司创办于1980年，是中国皮鞋行业的排头兵企业。公司总部坐落于温州，先后获得中国名牌产品...',
				info: '　　康奈集团有限公司创办于1980年，是中国皮鞋行业的排头兵企业。公司总部坐落于温州，先后获得中国名牌产品、中国出口名牌、中国真皮领先鞋王和全国质量奖、浙江省政府质量奖等500多项荣誉，营销网络遍布全球，在全国有专卖店（柜）3000多家，其中海外专卖店（柜）100多家。'
			}, {
				type: 'cg',
				title: '鸿星尔克',
				img: require('../static/images/case/hxek.png'),
				desc: '　　公司成立于2000年6月，总部位于厦门，经过多年励精图治，目前已发展为集研发、生产、销售为一体的大型服饰...',
				info: '　　公司成立于2000年6月，总部位于厦门，经过多年励精图治，目前已发展为集研发、生产、销售为一体的大型服饰企业。公司在全世界拥有店铺7000余家，产品行销欧洲、东南亚、中东、南北美洲、非洲等国家和地区，并相继斩获“中国500最具价值品牌”、“亚洲品牌500强”、“《福布斯》亚洲200佳”等殊荣。'
			}, {
				type: 'cg',
				title: 'DISSONA',
				img: require('../static/images/case/diss.png'),
				desc: '　　DISSONA，是一个具有国际视野，展现都市女性优雅形象的高阶轻奢手袋鞋履品牌，为中国消费者的时尚生活找...',
				info: '　　DISSONA，是一个具有国际视野，展现都市女性优雅形象的高阶轻奢手袋鞋履品牌，为中国消费者的时尚生活找到全新原创能量。其产品从都会时尚和当代艺术中汲取灵感，将时尚设计融入都市生活，塑造出都市女性自信、优雅、品味主张的生活方式。'
			}, {
				type: 'cd',
				title: '巧帛CHABER',
				img: require('../static/images/case/nvqqiaoj.png'),
				desc: '　　品牌创建于1984年，以精致手工和高品质做工见长，蕴含了「巧织衣帛」的原创精神，传达与生活密不可分的...',
				info: '　　品牌创建于1984年，以精致手工和高品质做工见长，蕴含了「巧织衣帛」的原创精神，传达与生活密不可分的自由、自在、自然的穿衣哲学，在消费者心目中建立了柔美、雅致服饰系列第一品牌的印象。“chaber”店柜目前在国内的数量有160余家，台湾地区有60余家，日本有10余家。'
			}, {
				type: 'ce',
				title: '班晓雪',
				img: require('../static/images/case/banxiaoxue.png'),
				desc: '　　班晓雪BANXIAOXUE原创文艺创立于2012年，包含女装和男装两条线路。品牌以自然而然为信仰，一方面是自...',
				info: '　　班晓雪BANXIAOXUE原创文艺创立于2012年，包含女装和男装两条线路。品牌以自然而然为信仰，一方面是自然状态下的衣，一方面是自然状态下的人。追求与传承东方美学和传统文化，关注当代的人文艺术和思考创意，致力于将自然主义的原创精神转化为当代的服饰文化以及生活方式。'
			}, {
				type: 'cc',
				title: 'NAVIGARE',
				img: require('../static/images/case/navigare.png'),
				desc: '　　Navigare作为意大利以海洋为主题的知名服饰品牌，从1961年其母公司Manifattura Riese在意大利创立开始...',
				info: '　　Navigare作为意大利以海洋为主题的知名服饰品牌，从1961年其母公司Manifattura Riese在意大利创立开始，至今已有58年的历史，在全球46个国家和地区，经营着近4000个品牌产品销售店铺。这个在意大利家喻户晓的品牌，畅销全球，每分钟就会卖出两件衣服。'
			}, {
				type: 'cc',
				title: 'VSKONNE',
				img: require('../static/images/case/wkss.jpg'),
				desc: '　　威斯康尼诞生于1982年的意大利，2000年进入中国市场，快受到中产阶级及社会名流的认可和青睐，并引领着...',
				info: '　　威斯康尼诞生于1982年的意大利，2000年进入中国市场，快受到中产阶级及社会名流的认可和青睐，并引领着中国高档男装成衣的发展。品牌定位在柔和、非结构性款式，同时以使用新型面料及优良制作而闻名。短时间内已经成为能与阿玛尼、古琦和瓦伦蒂诺齐名的意大利时尚品牌之一。'
			}, {
				type: 'ce',
				title: '飞鸟和新酒',
				img: require('../static/images/case/fnhxj.png'),
				desc: '　　飞鸟和新酒品牌成立于2012年，是一个诠释“自然、浪漫、自由”，倡导自然慢生活的生活方式类女装品牌...',
				info: '　　飞鸟和新酒品牌成立于2012年，是一个诠释“自然、浪漫、自由”，倡导自然慢生活的生活方式类女装品牌。坚持了海明控股集团“共同创造美好生活”的使命，提供了一种由穿着体验带来的回归自我、放松生活的新选择。'
			}, {
				type: 'ce',
				title: 'GMXY',
				img: require('../static/images/case/gmxy.png'),
				desc: '　　“古木夕羊”定位于知性、品位、风格、怡情的女性，设计风格细腻、简约、自然、内敛，前卫中蕴涵保守...',
				info: '　　“古木夕羊”定位于知性、品位、风格、怡情的女性，设计风格细腻、简约、自然、内敛，前卫中蕴涵保守，自然、环保的面料，体贴入微的剪裁，融个性于环境之中，创造一种心灵与身体的感动体验。连续多年获得“杭州十大女装品牌”等荣誉。'
			}, {
				type: 'cg',
				title: '曼卡龙',
				img: require('../static/images/case/mankal.png'),
				desc: '　　MCLON曼卡龙珠宝诞生于1994年，秉承专业的经营理念和服务精神，以不断追求创新设计为品牌核心。作为业界...',
				info: '　　MCLON曼卡龙珠宝诞生于1994年，秉承专业的经营理念和服务精神，以不断追求创新设计为品牌核心。作为业界为数不多的掌握稀缺珠宝原料资源的专业公司，分别在意大利的佛罗伦萨和中国的香港设立了珠宝设计研发中心，致力于为消费者提供紧跟时尚潮流，又极具设计内涵的珠宝饰品。'
			}, {
				type: 'cd',
				title: 'LuLualways',
				img: require('../static/images/case/lways.png'),
				desc: '　　LuLualways1995年由Paticial forgeal女士创立于法国巴黎玛黑区，秉承「我的法式生活」品牌理念，将当下...',
				info: '　　LuLualways1995年由Paticial forgeal女士创立于法国巴黎玛黑区，秉承「我的法式生活」品牌理念，将当下国外最流行的时装趋势和东方美学相结合，满足了亚洲现代女性对时尚魅力的表现诉求。自2003年开设中国大陆第一家专柜后迅速崛起，是“场景化体验式概念店”的弄潮儿和“新零售”的先行者。'
			}, {
				type: 'cb',
				title: '迪丰集团',
				img: require('../static/images/case/df.png'),
				desc: '　　旗下品牌ANDREW MACKENZIE/ VERRI/ DAVID MAYER NAMAN/ DIDIBOY/ LIU JO UOMO/ NOTA KIDS/ EHE...',
				info: '　　旗下品牌ANDREW MACKENZIE/ VERRI/ DAVID MAYER NAMAN/ DIDIBOY/ LIU JO UOMO/ NOTA KIDS/ EHE <br>　　迪丰集团始于1996年，乃大中华区最具权威的时尚传播集团，发展至今已成功行销管理国际知名品牌近二十个，其代理品牌横跨多元化高端服饰、生活时尚用品、化妆品与西式餐饮等多个经营领域。目前，迪丰旗下品牌在全国有500多个高品质终端店，乃引领大中华区时装零售市场潮流的先驱。'
			}, {
				type: 'cb',
				title: '安正时尚集团',
				img: require('../static/images/case/anz.png'),
				desc: '　　旗下拥有JZ玖姿、IMM尹默、ANZHENG安正、Fiona Chen（斐娜晨）、MOISSAC（摩萨克）五大各具特色的服装...',
				info: '　　旗下拥有JZ玖姿、IMM尹默、ANZHENG安正、Fiona Chen（斐娜晨）、MOISSAC（摩萨克）五大各具特色的服装品牌。<br>　　品牌产品畅销各地市场，集团业绩连续多年保持较快增长，在国内服装业界和消费者中有良好的口碑和强大的品牌号召力。'
			}, {
				type: 'cb',
				title: '凰艮时尚集团',
				img: require('../static/images/case/hply.png'),
				desc: '　　旗下品牌荷比俪/荷高/荷咖啡/荷茶公司集设计、生产、销售于一体，创意团队立足国内，兼具敏锐的国际时尚...',
				info: '　　旗下品牌荷比俪/荷高/荷咖啡/荷茶公司集设计、生产、销售于一体，创意团队立足国内，兼具敏锐的国际时尚触角；完善且强大的终端团队，支持着产品的创新与品牌文化的全面推广。2009年，华实齐生的HPLY诞生，历经五年蓬勃发展，开拓了一片属于自己的时尚天地，成为国内颇受瞩目的新锐高端女装品牌。'
			}, {
				type: 'cc',
				title: 'VERRI',
				img: require('../static/images/case/verri.png'),
				desc: '　　1972年，世界五大时装设计师之一的Leonardo Brugognone，在意大利米兰建立了首间名为VERRI的男士时装...',
				info: '　　1972年，世界五大时装设计师之一的Leonardo Brugognone，在意大利米兰建立了首间名为VERRI的男士时装精品店。舒适及体面的当代魅力设计，再加上优质选料及精致的手工制造是VERRI的特质。1978年VERRI开始了它的国际化征程，如今，VERRI在全球已有150多个销售网络。'
			}, {
				type: 'cc',
				title: '堡尼',
				img: require('../static/images/case/baoni.png'),
				desc: '　　堡尼（BONI）创始于1997年，作为意大利男装品牌，因其独特、创新的设计在男装界崭露头角，产品线涵盖...',
				info: '　　堡尼（BONI）创始于1997年，作为意大利男装品牌，因其独特、创新的设计在男装界崭露头角，产品线涵盖男装、皮件、配饰等整体男装精品系列，致力于为精英男士打造低调奢华、优雅精致的生活格调。'
			}, {
				type: 'cc',
				title: '路卡迪龙',
				img: require('../static/images/case/lukdl.png'),
				desc: '　　路卡迪龙（LUKA DILONG）男装于1886年创办于意大利，经过一百多年的发展，以其精湛的手工制作工艺...',
				info: '　　路卡迪龙（LUKA DILONG）男装于1886年创办于意大利，经过一百多年的发展，以其精湛的手工制作工艺成为欧洲的崇尚着装标志之一。作为欧洲知名品牌，2001年进入中国内地市场后迅速在国内各大城市一线商圈建立专卖店，赢得了上流社会人士的青睐。'
			}, {
				type: 'cc',
				title: 'J.D.V',
				img: require('../static/images/case/jdv.png'),
				desc: '　　J.D.V全称J-DifferentView，意为“与众不同的理念”。从2010年成立以来，以年轻消费群体为出发点，依靠华...',
				info: '　　J.D.V全称J-DifferentView，意为“与众不同的理念”。从2010年成立以来，以年轻消费群体为出发点，依靠华丽的设计，独特的搭配，攻占全国市场。静谧复古的空间，贴心周到的服务，整体视觉创作，J.D.V立志成为男装品牌的引领者，带给潮人们最迅驰新潮的时尚元素。'
			}, {
				type: 'cc',
				title: '蔓哈顿',
				img: require('../static/images/case/manhad.png'),
				desc: '　　蔓哈顿（MENHARDUM）是源自美国的时尚商务男装品牌。公司每年推出的具有国际时尚潮流的男装系列等有千余种...',
				info: '　　蔓哈顿（MENHARDUM）是源自美国的时尚商务男装品牌。公司每年推出的具有国际时尚潮流的男装系列等有千余种，产品款式在紧随欧美时尚潮流的同时，融入了东方文化的设计理念，引领着国内的时尚潮流和穿着理念，并受到中高档消费群体的青睐。'
			}, {
				type: 'cc',
				title: 'MARC O’POLO',
				img: require('../static/images/case/make.png'),
				desc: '　　Marc O’Polo（马可波罗）服装品牌创立于瑞典斯德哥尔摩。以简洁的款式和高品质的面料，配以利落明快的轮廓...',
				info: '　　Marc O’Polo（马可波罗）服装品牌创立于瑞典斯德哥尔摩。以简洁的款式和高品质的面料，配以利落明快的轮廓造型，迅速成为国际市场的新宠。目前总部位于德国，在世界各地拥有153家专卖店。'
			}, {
				type: 'cd',
				title: '杰茜莱',
				img: require('../static/images/case/jessyline.png'),
				desc: '　　JESSYLINE 起源于意大利时尚之都米兰，经历了时间洗礼却依旧昂然走在时尚浪尖。本着时尚、率性、迷人...',
				info: '　　JESSYLINE 起源于意大利时尚之都米兰，经历了时间洗礼却依旧昂然走在时尚浪尖。本着时尚、率性、迷人的设计理念，将意大利的热情、率性渗透于品牌的每一个细节，带给注重穿着自我体验的时尚女人一种生活态度。'
			}, {
				type: 'cd',
				title: '菀草壹',
				img: require('../static/images/case/wcaoy.png'),
				desc: '　　菀草壹成立于2008年，作为现代休闲女装的新兴品牌，包含“自然”和“城市”两条设计主线。“自然”线产品，风格...',
				info: '　　菀草壹成立于2008年，作为现代休闲女装的新兴品牌，包含“自然”和“城市”两条设计主线。“自然”线产品，风格文艺时髦，多采用宽松版型搭配线条与柔和色彩，印花点缀。“城市”线产品，主要为日常上班、朋友聚会和运动健身等场合的穿着服饰，款式简洁易穿搭。'
			}, {
				type: 'cd',
				title: '欧柏兰奴',
				img: require('../static/images/case/oubln.png'),
				desc: '　　OMNIALUO（欧柏兰奴）作为中国高级女装设计师品牌，由中国时装设计“金顶奖”得主、中国首位登台纽约时装周..',
				info: '　　OMNIALUO（欧柏兰奴）作为中国高级女装设计师品牌，由中国时装设计“金顶奖”得主、中国首位登台纽约时装周的女装设计师罗峥担任董事长兼艺术创意总监。品牌力求臻现都市知性女性优雅、浪漫、时尚的超然之美，被喻为“诗意的女装”，销售渠道覆盖全国32个省、市，拥有近200家门店。'
			}, {
				type: 'cd',
				title: 'III VIVINIKO',
				img: require('../static/images/case/iiivv.png'),
				desc: '　　III VIVINIKO（薇薏蔻）创立于2006年，以"简单的新鲜感"作为品牌设计理念，通过对"色彩、素材、体积"...',
				info: '　　III VIVINIKO（薇薏蔻）创立于2006年，以"简单的新鲜感"作为品牌设计理念，通过对"色彩、素材、体积"这三者关系的重新认识和塑造，在服装设计中融入对建筑美学的理解，营造具有时代特征的质感街头风格。 "简单纯粹、廓形鲜明"，具有显著的北欧时装风格。'
			}, {
				type: 'cd',
				title: '地素',
				img: require('../static/images/case/disu.png'),
				desc: '　　地素时尚股份有限公司，2002年创立于中国上海，是一家多品牌运作的时尚集团，有爱有梦有艺术体温...',
				info: '　　地素时尚股份有限公司，2002年创立于中国上海，是一家多品牌运作的时尚集团，有爱有梦有艺术体温，在时尚领域具有较高的知名度和影响力。截至2019年底，地素时尚旗下品牌的终端销售网络合计达千余家，市场占有率每年良性递增。'
			}, {
				type: 'cd',
				title: 'U/TI',
				img: require('../static/images/case/uit.png'),
				desc: '　　U/TI（尤缇）女装品牌始创于1989年，以“不刻意、自信、创意风尚”与“自然、放松、充满艺术想象的文艺特...',
				info: '　　U/TI（尤缇）女装品牌始创于1989年，以“不刻意、自信、创意风尚”与“自然、放松、充满艺术想象的文艺特质”作为品牌精神，充分展示了女性追逐个性但不张扬、追逐时尚但不盲从、追逐奢侈但不庸俗的个性，是欧洲各大著名时装周的宠儿，在巴黎、米兰、纽约等地，均获得了消费者的一致好评。'
			}, {
				type: 'cd',
				title: '芭蒂娜',
				img: require('../static/images/case/badina.png'),
				desc: '　　芭蒂娜是一个国际化全明星女装品牌，承袭于意大利艺术基因，其领衔设计师是当今国际著名时装艺术大师...',
				info: '　　芭蒂娜是一个国际化全明星女装品牌，承袭于意大利艺术基因，其领衔设计师是当今国际著名时装艺术大师—达尼埃拉—维加尼，品牌整体风格优雅时尚而女人味，1998年推出中国市场，作为国内服饰的一线品牌，成为中国女性向往欧式风格的流行标志。'
			}, {
				type: 'cd',
				title: 'JORYA',
				img: require('../static/images/case/jorya.png'),
				desc: '　　品牌始创于1992年，JORYA对女人有着独特的见解，秉持着以专业的设计和坚守产品质量的理念，让女性知...',
				info: '　　品牌始创于1992年，JORYA对女人有着独特的见解，秉持着以专业的设计和坚守产品质量的理念，让女性知性优雅的魅力全面绽放。其系列产品分为JORYA服饰、costume高级定制、礼服及配饰。'
			}, {
				type: 'cd',
				title: '飘蕾',
				img: require('../static/images/case/piaolei.png'),
				desc: '　　2019年，PÉOLEO与法国时尚创意⼯作室Studio 6, Paris合作，正式推出全新形象PL’6。品牌以“Paris Look...',
				info: '　　2019年，PÉOLEO与法国时尚创意⼯作室Studio 6, Paris合作，正式推出全新形象PL’6。品牌以“Paris Look”（巴黎式观感）为核⼼价值，并通过“⾃在、浪漫、魅⼒、活⼒、精致、时髦”这6个感觉加以解读，为社交时代的中国新中产⼥性设计具有当代质感与⾼级趣味的轻场合时装。'
			}, {
				type: 'cd',
				title: 'EP YAYING',
				img: require('../static/images/case/ep.png'),
				desc: '　　EP YAYING 雅莹是雅莹集团1988年创立的时尚女装品牌，致力于引领及展现中国时尚。拥有终端门店800余家...',
				info: '　　EP YAYING 雅莹是雅莹集团1988年创立的时尚女装品牌，致力于引领及展现中国时尚。拥有终端门店800余家，覆盖全国210个城市，遍布中国大陆、澳门地区以及马来西亚等地。其总部浙江雅莹集团有限公司是一家以品牌运营为核心的现代时装企业，并于2019年荣登浙江高新企业百强榜排名第20位。'
			}, {
				type: 'ce',
				title: 'N-ONE',
				img: require('../static/images/case/none.png'),
				desc: '　　N-one创立于2016年，是一家将零售变得有趣，将平民，集市和高贵的时装形成强烈对比的设计师集成概念品牌...',
				info: '　　N-one创立于2016年，是一家将零售变得有趣，将平民，集市和高贵的时装形成强烈对比的设计师集成概念品牌。同时也是一家全球买手设计师集合店，有来自国内外50+新锐设计师品牌风格集合。'
			}, {
				type: 'ce',
				title: 'Bouthentique',
				img: require('../static/images/case/btqii.png'),
				desc: '　　Bouthentique由Boutique and Authentic融合，意为往昔美好年代里，那些曾经篆刻在我们内心深处的细腻品...',
				info: '　　Bouthentique由Boutique and Authentic融合，意为往昔美好年代里，那些曾经篆刻在我们内心深处的细腻品质与意境。品牌强调品质感与舒适度的设计，阐述一种精细、优雅的审美感受，以简约形式与精致细节来描述低调内敛的文艺知性气息。'
			}, {
				type: 'ce',
				title: '卓卡',
				img: require('../static/images/case/zukkapro.png'),
				desc: '　　2006年上海卓卡贸易有限公司推出个性化休闲女装品牌ZUKKA PRO，她传递的是一种自由快乐的生活方式...',
				info: '　　2006年上海卓卡贸易有限公司推出个性化休闲女装品牌ZUKKA PRO，她传递的是一种自由快乐的生活方式。ZUKKA PRO崇尚人性的自由，善于发现生活的美好。在强调设计、品质感的同时，以令人欢愉明朗的服装带给穿着者轻松自在与乐趣。'
			}, {
				type: 'cf',
				title: 'Mitti',
				img: require('../static/images/case/mitti.png'),
				desc: '　　Mitti是成立于1996年的童装品牌。本着对于儿童身心的细致关爱， Mitti的服装崇尚永恒经典又符合现代个性，满足...',
				info: '　　Mitti是成立于1996年的童装品牌。本着对于儿童身心的细致关爱， Mitti的服装崇尚永恒经典又符合现代个性，满足儿童每个生活场景的着装需要。服装细节处理讲求高品质，面料坚持选用最优质的天然棉麻羊毛，强调舒适自然和优雅实用，为孩子提供温柔、舒适和天然的呵护。'
			}, {
				type: 'cf',
				title: 'jnby by JNBY',
				img: require('../static/images/case/jnbyjn.png'),
				desc: '　　2011年江南布衣集团推出了第一个童装品牌jnby by JNBY。品牌以“自由的想象力”为理念，为0-10岁的孩子...',
				info: '　　2011年江南布衣集团推出了第一个童装品牌jnby by JNBY。品牌以“自由的想象力”为理念，为0-10岁的孩子而设计，借助当代艺术的设计手法，以“自由、想象力、快乐、真实”为设计核心，汲取生活意趣，分享艺术与知识，传递美好的价值观念。'
			}, {
				type: 'cf',
				title: 'gxg.kids',
				img: require('../static/images/case/gxgkids.png'),
				desc: '　　gxg.kids品牌创立于2012年，在延续GXG主线品牌的前提下，gxg.kids运用紧贴潮流的设计手法，将时尚安全...',
				info: '　　gxg.kids品牌创立于2012年，在延续GXG主线品牌的前提下，gxg.kids运用紧贴潮流的设计手法，将时尚安全环保贴心的童装及时新鲜地传递给消费者。产品以时尚休闲为主，款式简约、富有趣味，色彩绚丽风格混搭。'
			}, {
				type: 'cf',
				title: '安奈儿',
				img: require('../static/images/case/annil.png'),
				desc: '　　安奈儿是一家主营中高端童装业务的自有品牌服装企业，旗下拥有“Annil安奈儿” 童装品牌。2017年于深圳证券...',
				info: '　　安奈儿是一家主营中高端童装业务的自有品牌服装企业，旗下拥有“Annil安奈儿” 童装品牌。2017年于深圳证券交易所中小企业板上市，成为国内A股童装第一股。经过二十多年的创业创新，安奈儿现在已经成为消费者最喜欢的童装品牌之一。'
			}, {
				type: 'cg',
				title: '千叶',
				img: require('../static/images/case/qyzb.png'),
				desc: '　　KEER千叶珠宝致力于设计美丽时尚的珠宝，分享爱与美。甄选上乘宝石，精湛工艺，悉心雕琢。自2001年成立以来...',
				info: '　　KEER千叶珠宝致力于设计美丽时尚的珠宝，分享爱与美。甄选上乘宝石，精湛工艺，悉心雕琢。自2001年成立以来，千叶珠宝一直致力于透过精致、时尚的产品，为女性消费者带来超越珠宝本身的生命愉悦感受，荣获了“亚洲品牌500强”、“中国企业成长百强”等荣誉称号。'
			}, {
				type: 'cg',
				title: '明牌',
				img: require('../static/images/case/mpzb.png'),
				desc: '　　明牌珠宝，中国最大的珠宝企业之一，全球领先铂金首饰生产商与零售商，行业工艺标准制定者之一。自1987年...',
				info: '　　明牌珠宝，中国最大的珠宝企业之一，全球领先铂金首饰生产商与零售商，行业工艺标准制定者之一。自1987年创建以来，明牌珠宝立足于先人一步的全球整合实力和雄厚企业背景，现在已发展成为集研发、设计、生产、销售于一体的大型专业珠宝企业，涵盖钻石、黄金、K金在内的全品类珠宝。'
			}, {
				type: 'cg',
				title: 'DKNY',
				img: require('../static/images/case/dkny.png'),
				desc: '　　DKNY（唐可娜儿）创立于1989年，发源于美国纽约。是一个前卫、时尚、休闲的时装品牌，产品包括男女装成衣...',
				info: '　　DKNY（唐可娜儿）创立于1989年，发源于美国纽约。是一个前卫、时尚、休闲的时装品牌，产品包括男女装成衣，鞋履，包袋配饰，内衣，腕表，及香氛等。以更前卫、更时尚、更休闲的手法描绘了纽约不同文化、不同生活方式的时代气息。'
			}, {
				type: 'cg',
				title: '方所',
				img: require('../static/images/case/fangsuo.png'),
				desc: '　　方所由中国服装协会副会长，文化创意界知名企业家毛继鸿于2011年一手创办。方所不仅是一家书店，更是一个...',
				info: '　　方所由中国服装协会副会长，文化创意界知名企业家毛继鸿于2011年一手创办。方所不仅是一家书店，更是一个文化平台，一种未来的生活形态。它是一家集书店、美学生活、咖啡、展览空间与服饰时尚等混业经营为一体的集成店。'
			}, {
				type: 'cg',
				title: 'lOOK眼镜',
				img: require('../static/images/case/look.png'),
				desc: '　　LOOK眼镜创始于2013年，隶属于森马服饰和韩国SISUN时装集团合资企业——上海盛夏服饰有限公司。秉承“打...',
				info: '　　LOOK眼镜创始于2013年，隶属于森马服饰和韩国SISUN时装集团合资企业——上海盛夏服饰有限公司。秉承“打破既有概念，使眼镜转变为一种潮流配饰”理念，针对不同人群进行产品开发，关注细节、注重用户感受，鼓励客户尝试不同风格的眼镜搭配，彰显多样的形象魅力。'
			}],
			caseList: [{
				type: 'cb',
				title: '江南布衣集团',
				img: require('../static/images/case/jnby.png'),
				desc: '　　旗下品牌JNBY/速写/jnby by JNBY/less/POMME DE TERRE蓬马/JNBYHOME/SAMO/REVERB/LASU MIN SOLA/A...',
				info: '　　旗下品牌JNBY/速写/jnby by JNBY/less/POMME DE TERRE蓬马/JNBYHOME/SAMO/REVERB/LASU MIN SOLA/A PERSONAL NOTE 73 <br>　　江南布衣集团由中国设计师李琳创立于 1994年，并于2016年10月在香港正式上市，总部位于中国杭州。集团以设计作为导向，以多品牌模式及全渠道互动平台在线上及线下进行产品的销售和推广。截止至目前，在中国及17个其他国家及地区的超过1900家零售门店。'
			}, {
				type: 'cb',
				title: '中哲慕尚集团',
				img: require('../static/images/case/gxg.png'),
				desc: '　　旗下品牌 GXG/gxg jeans/gxg.kids/Yatlas/2XU 慕尚集团是一家专注于中国时尚男装市场的公司，创办于2007年，旗...',
				info: '　　旗下品牌 GXG/gxg jeans/gxg.kids/Yatlas/2XU <br>　　慕尚集团是一家专注于中国时尚男装市场的公司，创办于2007年，旗下品牌门店覆盖全国32个省市自治区，门店总数达到2000多家，2017年度品牌总销售额达到105亿。 '
			}, {
				type: 'cb',
				title: '欣贺集团',
				img: require('../static/images/case/hx.png'),
				desc: '　　旗下品牌JORYA/JORYAWEEKEND/GIVHSHYH巨式国际/ANMANI恩曼琳/CAROLINE卡洛琳/AIVEI艾薇欣贺股份是...',
				info: '　　旗下品牌JORYA/JORYAWEEKEND/GIVHSHYH巨式国际/ANMANI恩曼琳/CAROLINE卡洛琳/AIVEI<br>　　艾薇欣贺股份是一家国际一级流行时尚女装设计、生产与销售一体的国内知名女装企业。经过多年精心的市场开拓与品牌推广，公司现今除厦门总部外，分支机构遍布于国内外一线城市，并于国内众多知名高档商场开设有近700家专卖店和旗舰店。'
			}, {
				type: 'cb',
				title: '马克华菲集团',
				img: require('../static/images/case/mkhf.png'),
				desc: '　　旗下品牌FAIRWHALE女装/ FAIRWHALE男装/ Reshake/ DEBRAND/ CAMEL ACTIVE/ SPACE5 马克华菲品牌诞...',
				info: '　　旗下品牌FAIRWHALE女装/ FAIRWHALE男装/ Reshake/ DEBRAND/ CAMEL ACTIVE/ SPACE5 <br>　　马克华菲品牌诞生于2000年，公司在十年多的时间里开设专卖店百余家，遍及国内各大中城市，产品亦日趋多样化，目前已涵盖国际时尚男装、马克华菲男女装、鞋、包、皮具、香水及配饰等多个领域。核心客群是那些热爱时尚和休闲的都市高级白领及追求高品质生活的中上阶层。'
			}, {
				type: 'cb',
				title: '红纺文化',
				img: require('../static/images/case/hf.png'),
				desc: '　　旗下品牌paul frank/ pancoat/ the Simpsons/ tokidoki/ Altips/ SESAME STREET/ RE...',
				info: '　　旗下品牌paul frank/ pancoat/ the Simpsons/ tokidoki/ Altips/ SESAME STREET/ RE集合店<br>　　红纺文化是一家IP商业转化集团。以IP运营为核心，搭建内容、商品、营销、媒体、渠道为产业链的品牌文化投资管理集团,致力成为IP商业转化佼佼者。秉承“红纺中国、美利天下”的企业理念，经历数次产业转型，站在行业前端，在中国开辟了潮流版块市场。'
			}, {
				type: 'cb',
				title: '迪丰集团',
				img: require('../static/images/case/df.png'),
				desc: '　　旗下品牌ANDREW MACKENZIE/ VERRI/ DAVID MAYER NAMAN/ DIDIBOY/ LIU JO UOMO/ NOTA KIDS/ EHE...',
				info: '　　旗下品牌ANDREW MACKENZIE/ VERRI/ DAVID MAYER NAMAN/ DIDIBOY/ LIU JO UOMO/ NOTA KIDS/ EHE <br>　　迪丰集团始于1996年，乃大中华区最具权威的时尚传播集团，发展至今已成功行销管理国际知名品牌近二十个，其代理品牌横跨多元化高端服饰、生活时尚用品、化妆品与西式餐饮等多个经营领域。目前，迪丰旗下品牌在全国有500多个高品质终端店，乃引领大中华区时装零售市场潮流的先驱。'
			}, {
				type: 'cb',
				title: '安正时尚集团',
				img: require('../static/images/case/anz.png'),
				desc: '　　旗下拥有JZ玖姿、IMM尹默、ANZHENG安正、Fiona Chen（斐娜晨）、MOISSAC（摩萨克）五大各具特色的...',
				info: '　　旗下拥有JZ玖姿、IMM尹默、ANZHENG安正、Fiona Chen（斐娜晨）、MOISSAC（摩萨克）五大各具特色的服装品牌。<br>　　品牌产品畅销各地市场，集团业绩连续多年保持较快增长，在国内服装业界和消费者中有良好的口碑和强大的品牌号召力。'
			}, {
				type: 'cb',
				title: '凰艮时尚集团',
				img: require('../static/images/case/hply.png'),
				desc: '　　旗下品牌荷比俪/荷高/荷咖啡/荷茶公司集设计、生产、销售于一体，创意团队立足国内，兼具敏锐的国际...',
				info: '　　旗下品牌荷比俪/荷高/荷咖啡/荷茶<br>　　公司集设计、生产、销售于一体，创意团队立足国内，兼具敏锐的国际时尚触角；完善且强大的终端团队，支持着产品的创新与品牌文化的全面推广。2009年，华实齐生的HPLY诞生，历经五年蓬勃发展，开拓了一片属于自己的时尚天地，成为国内颇受瞩目的新锐高端女装品牌。'
			}, {
				type: 'cb',
				title: '美特斯邦威',
				img: require('../static/images/case/mb.png'),
				desc: '　　上海美特斯邦威服饰股份有限公司始创于1995年，目前旗下拥有“Metersbonwe”和“ME&CITY”两大时尚休闲品牌...',
				info: '　　上海美特斯邦威服饰股份有限公司始创于1995年，目前旗下拥有“Metersbonwe”和“ME&CITY”两大时尚休闲品牌；“米喜迪”和“MooMoo”两大童装品牌，慢生活倡导品牌“CH’IN褀”。作为中国服装产业代表企业之一，浸润时尚产业20余年，秉承“世界的裁缝”的梦想，以“不走寻常路”的精神不断进行企业的发展创新和向上突破。'
			}, {
				type: 'cc',
				title: '堡尼',
				img: require('../static/images/case/baoni.png'),
				desc: '　　堡尼（BONI）创始于1997年，作为意大利男装品牌，因其独特、创新的设计在男装界崭露头角，产品线涵盖男装...',
				info: '　　堡尼（BONI）创始于1997年，作为意大利男装品牌，因其独特、创新的设计在男装界崭露头角，产品线涵盖男装、皮件、配饰等整体男装精品系列，致力于为精英男士打造低调奢华、优雅精致的生活格调。'
			}, {
				type: 'cc',
				title: '路卡迪龙',
				img: require('../static/images/case/lukdl.png'),
				desc: '　　路卡迪龙（LUKA DILONG）男装于1886年创办于意大利，经过一百多年的发展，以其精湛的手工制作工艺成为...',
				info: '　　路卡迪龙（LUKA DILONG）男装于1886年创办于意大利，经过一百多年的发展，以其精湛的手工制作工艺成为欧洲的崇尚着装标志之一。作为欧洲知名品牌，2001年进入中国内地市场后迅速在国内各大城市一线商圈建立专卖店，赢得了上流社会人士的青睐。'
			}, {
				type: 'cc',
				title: '沙驰',
				img: require('../static/images/case/shachi.png'),
				desc: '　　Satchi是欧洲高端商务男装品牌，也是全球CEO峰会唯一指定男装品牌。自上世纪八十年代进入亚洲，以其卓越...',
				info: '　　Satchi是欧洲高端商务男装品牌，也是全球CEO峰会唯一指定男装品牌。自上世纪八十年代进入亚洲，以其卓越品质迅速扩展，于全国400多家顶级商场开设品牌专柜；为各界成功男士带来欧洲经典着装和箱包的新体验。'
			}, {
				type: 'cc',
				title: '哈吉斯',
				img: require('../static/images/case/hajs.png'),
				desc: '　　一线休闲男装HAZZYS创立于2000年。综合了精心裁剪和当今国际流行的色彩，洋溢着浓郁的英伦风情，有着大...',
				info: '　　一线休闲男装HAZZYS创立于2000年。综合了精心裁剪和当今国际流行的色彩，洋溢着浓郁的英伦风情，有着大不列颠贵族家庭的高贵外表，保守、高雅的生活风尚中注入率真、自在、谐趣又不失奢华雅致的时尚元素，让HAZZYS一族体验英国贵族式的经典、奢华与尊贵，展现不凡的生活品位。'
			}, {
				type: 'cc',
				title: 'VSKONNE',
				img: require('../static/images/case/wkss.jpg'),
				desc: '　　威斯康尼诞生于1982年的意大利，2000年进入中国市场，快受到中产阶级及社会名流的认可和青睐，并引领着...',
				info: '　　威斯康尼诞生于1982年的意大利，2000年进入中国市场，快受到中产阶级及社会名流的认可和青睐，并引领着中国高档男装成衣的发展。品牌定位在柔和、非结构性款式，同时以使用新型面料及优良制作而闻名。短时间内已经成为能与阿玛尼、古琦和瓦伦蒂诺齐名的意大利时尚品牌之一。'
			}, {
				type: 'cc',
				title: 'NAVIGARE',
				img: require('../static/images/case/navigare.png'),
				desc: '　　Navigare作为意大利以海洋为主题的知名服饰品牌，从1961年其母公司Manifattura Riese在意大利创立开始，...',
				info: '　　Navigare作为意大利以海洋为主题的知名服饰品牌，从1961年其母公司Manifattura Riese在意大利创立开始，至今已有58年的历史，在全球46个国家和地区，经营着近4000个品牌产品销售店铺。这个在意大利家喻户晓的品牌，畅销全球，每分钟就会卖出两件衣服。'
			}, {
				type: 'cc',
				title: '蔓哈顿',
				img: require('../static/images/case/manhad.png'),
				desc: '　　蔓哈顿（MENHARDUM）是源自美国的时尚商务男装品牌。公司每年推出的具有国际时尚潮流的男装系列等有...',
				info: '　　蔓哈顿（MENHARDUM）是源自美国的时尚商务男装品牌。公司每年推出的具有国际时尚潮流的男装系列等有千余种，产品款式在紧随欧美时尚潮流的同时，融入了东方文化的设计理念，引领着国内的时尚潮流和穿着理念，并受到中高档消费群体的青睐。'
			}, {
				type: 'cc',
				title: '柒牌',
				img: require('../static/images/case/qipai.png'),
				desc: '　　始创于1979年，集团拥有世界一流的服装生产设备，并在全国31个省、市（自治区）设立3000多家专卖店。多年...',
				info: '　　始创于1979年，集团拥有世界一流的服装生产设备，并在全国31个省、市（自治区）设立3000多家专卖店。多年来产品销售收入、利润总额名列全国服装行业前十强。2019年，柒牌品牌价值达到615.78亿，连续15年入围“中国500最具价值品牌”榜。'
			}, {
				type: 'cc',
				title: 'VERRI',
				img: require('../static/images/case/verri.png'),
				desc: '　　1972年，世界五大时装设计师之一的Leonardo Brugognone，在意大利米兰建立了首间名为VERRI的男士时装...',
				info: '　　1972年，世界五大时装设计师之一的Leonardo Brugognone，在意大利米兰建立了首间名为VERRI的男士时装精品店。舒适及体面的当代魅力设计，再加上优质选料及精致的手工制造是VERRI的特质。1978年VERRI开始了它的国际化征程，如今，VERRI在全球已有150多个销售网络。'
			}, {
				type: 'cc',
				title: 'J.D.V',
				img: require('../static/images/case/jdv.png'),
				desc: '　　J.D.V全称J-DifferentView，意为“与众不同的理念”。从2010年成立以来，以年轻消费群体为出发点，依靠华...',
				info: '　　J.D.V全称J-DifferentView，意为“与众不同的理念”。从2010年成立以来，以年轻消费群体为出发点，依靠华丽的设计，独特的搭配，攻占全国市场。静谧复古的空间，贴心周到的服务，整体视觉创作，J.D.V立志成为男装品牌的引领者，带给潮人们最迅驰新潮的时尚元素。'
			}, {
				type: 'cc',
				title: 'MARC O’POLO',
				img: require('../static/images/case/make.png'),
				desc: '　　Marc O’Polo（马可波罗）服装品牌创立于瑞典斯德哥尔摩。以简洁的款式和高品质的面料，配以利落明快的轮廓...',
				info: '　　Marc O’Polo（马可波罗）服装品牌创立于瑞典斯德哥尔摩。以简洁的款式和高品质的面料，配以利落明快的轮廓造型，迅速成为国际市场的新宠。目前总部位于德国，在世界各地拥有153家专卖店。'
			}, {
				type: 'cc',
				title: '七匹狼',
				img: require('../static/images/case/sw.png'),
				desc: '　　七匹狼集团于1990年创立， 作为中国服装业首家上市公司，被誉为“中国休闲装之王”和“茄克专家”，其男装的...',
				info: '　　七匹狼集团于1990年创立， 作为中国服装业首家上市公司，被誉为“中国休闲装之王”和“茄克专家”，其男装的特许专卖销售网络在全国覆盖面积达90%以上，拥有专卖店铺4000多家。“七匹狼牌茄克衫”连续十年荣获同类产品市场占有率第一。'
			}, {
				type: 'cd',
				title: '玖姿',
				img: require('../static/images/case/jiuzi.png'),
				desc: '　　JZ玖姿为安正时尚集团股份有限公司旗下品牌，成立于2001年，致力于追求时尚、精致与实用美学的完...',
				info: '　　JZ玖姿为安正时尚集团股份有限公司旗下品牌，成立于2001年，致力于追求时尚、精致与实用美学的完美平衡。秉承“优雅、大气、经典、时尚”的设计理念，JZ玖姿运用优质上等的面料、精致的细节装饰、优雅的色彩与图案表现，演绎现代都市女性的自信、优雅、女人味。'
			}, {
				type: 'cd',
				title: 'JORYA',
				img: require('../static/images/case/jorya.png'),
				desc: '　　品牌始创于1992年，JORYA对女人有着独特的见解，秉持着以专业的设计和坚守产品质量的理念，让女性知...',
				info: '　　品牌始创于1992年，JORYA对女人有着独特的见解，秉持着以专业的设计和坚守产品质量的理念，让女性知性优雅的魅力全面绽放。其系列产品分为JORYA服饰、costume高级定制、礼服及配饰。'
			}, {
				type: 'cd',
				title: '地素',
				img: require('../static/images/case/disu.png'),
				desc: '　　地素时尚股份有限公司，2002年创立于中国上海，是一家多品牌运作的时尚集团，有爱有梦有艺术体温...',
				info: '　　地素时尚股份有限公司，2002年创立于中国上海，是一家多品牌运作的时尚集团，有爱有梦有艺术体温，在时尚领域具有较高的知名度和影响力。截至2019年底，地素时尚旗下品牌的终端销售网络合计达千余家，市场占有率每年良性递增。'
			}, {
				type: 'cd',
				title: '红袖',
				img: require('../static/images/case/hongxiu.png'),
				desc: '　　中国十佳设计师刘华于1995年创办女装品牌红袖。红袖倡导品质、品味与知性的生活态度，不断在旅行中寻找文化...',
				info: '　　中国十佳设计师刘华于1995年创办女装品牌红袖。红袖倡导品质、品味与知性的生活态度，不断在旅行中寻找文化艺术与设计生活的时尚灵感，以高品质与合理的价格提供触手可及的国际前沿时尚生活方式，成就女性无限的可能。2016年荣获“最佳青春女装品牌”称号，在全国各地拥有门店1600余家。'
			}, {
				type: 'cd',
				title: 'LuLualways',
				img: require('../static/images/case/lways.png'),
				desc: '　　LuLualways1995年由Paticial forgeal女士创立于法国巴黎玛黑区，秉承「我的法式生活」品牌理念，将当下国...',
				info: '　　LuLualways1995年由Paticial forgeal女士创立于法国巴黎玛黑区，秉承「我的法式生活」品牌理念，将当下国外最流行的时装趋势和东方美学相结合，满足了亚洲现代女性对时尚魅力的表现诉求。自2003年开设中国大陆第一家专柜后迅速崛起，是“场景化体验式概念店”的弄潮儿和“新零售”的先行者。'
			}, {
				type: 'cd',
				title: '欧柏兰奴',
				img: require('../static/images/case/oubln.png'),
				desc: '　　OMNIALUO（欧柏兰奴）作为中国高级女装设计师品牌，由中国时装设计“金顶奖”得主、中国首位登台纽约时装..',
				info: '　　OMNIALUO（欧柏兰奴）作为中国高级女装设计师品牌，由中国时装设计“金顶奖”得主、中国首位登台纽约时装周的女装设计师罗峥担任董事长兼艺术创意总监。品牌力求臻现都市知性女性优雅、浪漫、时尚的超然之美，被喻为“诗意的女装”，销售渠道覆盖全国32个省、市，拥有近200家门店。'
			}, {
				type: 'cd',
				title: 'U/TI',
				img: require('../static/images/case/uit.png'),
				desc: '　　U/TI（尤缇）女装品牌始创于1989年，以“不刻意、自信、创意风尚”与“自然、放松、充满艺术想象的文艺特...',
				info: '　　U/TI（尤缇）女装品牌始创于1989年，以“不刻意、自信、创意风尚”与“自然、放松、充满艺术想象的文艺特质”作为品牌精神，充分展示了女性追逐个性但不张扬、追逐时尚但不盲从、追逐奢侈但不庸俗的个性，是欧洲各大著名时装周的宠儿，在巴黎、米兰、纽约等地，均获得了消费者的一致好评。'
			}, {
				type: 'cd',
				title: '芭蒂娜',
				img: require('../static/images/case/badina.png'),
				desc: '　　芭蒂娜是一个国际化全明星女装品牌，承袭于意大利艺术基因，其领衔设计师是当今国际著名时装艺术大师...',
				info: '　　芭蒂娜是一个国际化全明星女装品牌，承袭于意大利艺术基因，其领衔设计师是当今国际著名时装艺术大师—达尼埃拉—维加尼，品牌整体风格优雅时尚而女人味，1998年推出中国市场，作为国内服饰的一线品牌，成为中国女性向往欧式风格的流行标志。'
			}, {
				type: 'cd',
				title: '飘蕾',
				img: require('../static/images/case/piaolei.png'),
				desc: '　　2019年，PÉOLEO与法国时尚创意⼯作室Studio 6, Paris合作，正式推出全新形象PL’6。品牌以“Paris Look...',
				info: '　　2019年，PÉOLEO与法国时尚创意⼯作室Studio 6, Paris合作，正式推出全新形象PL’6。品牌以“Paris Look”（巴黎式观感）为核⼼价值，并通过“⾃在、浪漫、魅⼒、活⼒、精致、时髦”这6个感觉加以解读，为社交时代的中国新中产⼥性设计具有当代质感与⾼级趣味的轻场合时装。'
			}, {
				type: 'cd',
				title: '杰茜莱',
				img: require('../static/images/case/jessyline.png'),
				desc: '　　JESSYLINE 起源于意大利时尚之都米兰，经历了时间洗礼却依旧昂然走在时尚浪尖。本着时尚、率性、迷人...',
				info: '　　JESSYLINE 起源于意大利时尚之都米兰，经历了时间洗礼却依旧昂然走在时尚浪尖。本着时尚、率性、迷人的设计理念，将意大利的热情、率性渗透于品牌的每一个细节，带给注重穿着自我体验的时尚女人一种生活态度。'
			}, {
				type: 'cd',
				title: '巧帛CHABER',
				img: require('../static/images/case/nvqqiaoj.png'),
				desc: '　　品牌创建于1984年，以精致手工和高品质做工见长，蕴含了「巧织衣帛」的原创精神，传达与生活密不可分的自由...',
				info: '　　品牌创建于1984年，以精致手工和高品质做工见长，蕴含了「巧织衣帛」的原创精神，传达与生活密不可分的自由、自在、自然的穿衣哲学，在消费者心目中建立了柔美、雅致服饰系列第一品牌的印象。“chaber”店柜目前在国内的数量有160余家，台湾地区有60余家，日本有10余家。'
			}, {
				type: 'cd',
				title: '菀草壹',
				img: require('../static/images/case/wcaoy.png'),
				desc: '　　菀草壹成立于2008年，作为现代休闲女装的新兴品牌，包含“自然”和“城市”两条设计主线。“自然”线产品，风格...',
				info: '　　菀草壹成立于2008年，作为现代休闲女装的新兴品牌，包含“自然”和“城市”两条设计主线。“自然”线产品，风格文艺时髦，多采用宽松版型搭配线条与柔和色彩，印花点缀。“城市”线产品，主要为日常上班、朋友聚会和运动健身等场合的穿着服饰，款式简洁易穿搭。'
			}, {
				type: 'cd',
				title: 'III VIVINIKO',
				img: require('../static/images/case/iiivv.png'),
				desc: '　　III VIVINIKO（薇薏蔻）创立于2006年，以"简单的新鲜感"作为品牌设计理念，通过对"色彩、素材、体积"这三...',
				info: '　　III VIVINIKO（薇薏蔻）创立于2006年，以"简单的新鲜感"作为品牌设计理念，通过对"色彩、素材、体积"这三者关系的重新认识和塑造，在服装设计中融入对建筑美学的理解，营造具有时代特征的质感街头风格。 "简单纯粹、廓形鲜明"，具有显著的北欧时装风格。'
			}, {
				type: 'cd',
				title: 'EP YAYING',
				img: require('../static/images/case/ep.png'),
				desc: '　　EP YAYING 雅莹是雅莹集团1988年创立的时尚女装品牌，致力于引领及展现中国时尚。拥有终端门店800余家...',
				info: '　　EP YAYING 雅莹是雅莹集团1988年创立的时尚女装品牌，致力于引领及展现中国时尚。拥有终端门店800余家，覆盖全国210个城市，遍布中国大陆、澳门地区以及马来西亚等地。其总部浙江雅莹集团有限公司是一家以品牌运营为核心的现代时装企业，并于2019年荣登浙江高新企业百强榜排名第20位。'
			}, {
				type: 'ce',
				title: 'N-ONE',
				img: require('../static/images/case/none.png'),
				desc: '　　N-one创立于2016年，是一家将零售变得有趣，将平民，集市和高贵的时装形成强烈对比的设计师集成概念品牌...',
				info: '　　N-one创立于2016年，是一家将零售变得有趣，将平民，集市和高贵的时装形成强烈对比的设计师集成概念品牌。同时也是一家全球买手设计师集合店，有来自国内外50+新锐设计师品牌风格集合。'
			}, {
				type: 'ce',
				title: '飞鸟和新酒',
				img: require('../static/images/case/fnhxj.png'),
				desc: '　　飞鸟和新酒品牌成立于2012年，是一个诠释“自然、浪漫、自由”，倡导自然慢生活的生活方式类女装品牌。坚持...',
				info: '　　飞鸟和新酒品牌成立于2012年，是一个诠释“自然、浪漫、自由”，倡导自然慢生活的生活方式类女装品牌。坚持了海明控股集团“共同创造美好生活”的使命，提供了一种由穿着体验带来的回归自我、放松生活的新选择。'
			}, {
				type: 'ce',
				title: 'GMXY',
				img: require('../static/images/case/gmxy.png'),
				desc: '　　“古木夕羊”定位于知性、品位、风格、怡情的女性，设计风格细腻、简约、自然、内敛，前卫中蕴涵保守，自然...',
				info: '　　“古木夕羊”定位于知性、品位、风格、怡情的女性，设计风格细腻、简约、自然、内敛，前卫中蕴涵保守，自然、环保的面料，体贴入微的剪裁，融个性于环境之中，创造一种心灵与身体的感动体验。连续多年获得“杭州十大女装品牌”等荣誉。'
			}, {
				type: 'ce',
				title: 'Bouthentique',
				img: require('../static/images/case/btqii.png'),
				desc: '　　Bouthentique由Boutique and Authentic融合，意为往昔美好年代里，那些曾经篆刻在我们内心深处的细腻品...',
				info: '　　Bouthentique由Boutique and Authentic融合，意为往昔美好年代里，那些曾经篆刻在我们内心深处的细腻品质与意境。品牌强调品质感与舒适度的设计，阐述一种精细、优雅的审美感受，以简约形式与精致细节来描述低调内敛的文艺知性气息。'
			}, {
				type: 'ce',
				title: 'D-HARRY',
				img: require('../static/images/case/dhappy.png'),
				desc: '　　D-HARRY（迪哈利）始创于1991年，源于意大利时尚品牌。迪哈利以西方复古为美学基因，同步国际时装潮流...',
				info: '　　D-HARRY（迪哈利）始创于1991年，源于意大利时尚品牌。迪哈利以西方复古为美学基因，同步国际时装潮流趋势，将东方情怀与西方复古完美融合。通过经典、怀旧的款式以及对细节与剪裁的注重，展现欧洲贵族式的复古与高雅。'
			}, {
				type: 'ce',
				title: '达衣岩',
				img: require('../static/images/case/yanyida.png'),
				desc: '　　达衣岩是为艺术而生的中国设计师品牌，它坚持为热爱生活的都市知性女性创造有亲和力、有艺术温度的服...',
				info: '　　达衣岩是为艺术而生的中国设计师品牌，它坚持为热爱生活的都市知性女性创造有亲和力、有艺术温度的服装。质地主要以天然原创棉为主，讲究舒适。'
			}, {
				type: 'ce',
				title: '班晓雪',
				img: require('../static/images/case/banxiaoxue.png'),
				desc: '　　班晓雪BANXIAOXUE原创文艺创立于2012年，包含女装和男装两条线路。品牌以自然而然为信仰，一方面是自...',
				info: '　　班晓雪BANXIAOXUE原创文艺创立于2012年，包含女装和男装两条线路。品牌以自然而然为信仰，一方面是自然状态下的衣，一方面是自然状态下的人。追求与传承东方美学和传统文化，关注当代的人文艺术和思考创意，致力于将自然主义的原创精神转化为当代的服饰文化以及生活方式。'
			}, {
				type: 'ce',
				title: '卓卡',
				img: require('../static/images/case/zukkapro.png'),
				desc: '　　2006年上海卓卡贸易有限公司推出个性化休闲女装品牌ZUKKA PRO，她传递的是一种自由快乐的生活方式...',
				info: '　　2006年上海卓卡贸易有限公司推出个性化休闲女装品牌ZUKKA PRO，她传递的是一种自由快乐的生活方式。ZUKKA PRO崇尚人性的自由，善于发现生活的美好。在强调设计、品质感的同时，以令人欢愉明朗的服装带给穿着者轻松自在与乐趣。'
			}, {
				type: 'cf',
				title: '巴拉巴拉',
				img: require('../static/images/case/balabala.png'),
				desc: '　　巴拉巴拉是森马服饰于2002年创立的具有国际视野的时尚大众童装品牌。产品全面覆盖0-16岁儿童的服装、童鞋...',
				info: '　　巴拉巴拉是森马服饰于2002年创立的具有国际视野的时尚大众童装品牌。产品全面覆盖0-16岁儿童的服装、童鞋、配饰品类，以“童年不同样”的品牌理念，为孩子们提供既时尚又实用的儿童服饰产品，让孩子们享受美好自在的童年。服装销量连续数年排名第一，稳居行业龙头。'
			}, {
				type: 'cf',
				title: '马·拉丁',
				img: require('../static/images/case/malad.png'),
				desc: '　　原创设计师童装品牌，来自生活和大自然的想象力、温暖的插画绘本，设计师运用丰富自然的图形和印象，尊重...',
				info: '　　原创设计师童装品牌，来自生活和大自然的想象力、温暖的插画绘本，设计师运用丰富自然的图形和印象，尊重儿童的个体和特质，传递快乐，更注重孩子心灵的陪伴。'
			}, {
				type: 'cf',
				title: 'ROOKIE',
				img: require('../static/images/case/rookie.png'),
				desc: '　　“Rookie-China”隶属世界童装零售巨头美国“HADDAD”公司旗下。定位为运动的、时尚的和休闲的童装与青少年...',
				info: '　　“Rookie-China”隶属世界童装零售巨头美国“HADDAD”公司旗下。定位为运动的、时尚的和休闲的童装与青少年装两大模块，涉及童装及青少年装各品类的细分市场，集合不同风格及不同品类的品牌与产品，这种全新的一站式购物体验，在国内童装界算是开创了先河。'
			}, {
				type: 'cf',
				title: 'Mitti',
				img: require('../static/images/case/mitti.png'),
				desc: '　　Mitti是成立于1996年的童装品牌。本着对于儿童身心的细致关爱， Mitti的服装崇尚永恒经典又符合现代个性...',
				info: '　　Mitti是成立于1996年的童装品牌。本着对于儿童身心的细致关爱， Mitti的服装崇尚永恒经典又符合现代个性，满足儿童每个生活场景的着装需要。服装细节处理讲求高品质，面料坚持选用最优质的天然棉麻羊毛，强调舒适自然和优雅实用，为孩子提供温柔、舒适和天然的呵护。'
			}, {
				type: 'cf',
				title: 'jnby by JNBY',
				img: require('../static/images/case/jnbyjn.png'),
				desc: '　　2011年江南布衣集团推出了第一个童装品牌jnby by JNBY。品牌以“自由的想象力”为理念，为0-10岁的孩子而...',
				info: '　　2011年江南布衣集团推出了第一个童装品牌jnby by JNBY。品牌以“自由的想象力”为理念，为0-10岁的孩子而设计，借助当代艺术的设计手法，以“自由、想象力、快乐、真实”为设计核心，汲取生活意趣，分享艺术与知识，传递美好的价值观念。'
			}, {
				type: 'cf',
				title: 'gxg.kids',
				img: require('../static/images/case/gxgkids.png'),
				desc: '　　gxg.kids品牌创立于2012年，在延续GXG主线品牌的前提下，gxg.kids运用紧贴潮流的设计手法，将时尚安全...',
				info: '　　gxg.kids品牌创立于2012年，在延续GXG主线品牌的前提下，gxg.kids运用紧贴潮流的设计手法，将时尚安全环保贴心的童装及时新鲜地传递给消费者。产品以时尚休闲为主，款式简约、富有趣味，色彩绚丽风格混搭。'
			}, {
				type: 'cf',
				title: '安奈儿',
				img: require('../static/images/case/annil.png'),
				desc: '　　安奈儿是一家主营中高端童装业务的自有品牌服装企业，旗下拥有“Annil安奈儿” 童装品牌。2017年于深圳证券...',
				info: '　　安奈儿是一家主营中高端童装业务的自有品牌服装企业，旗下拥有“Annil安奈儿” 童装品牌。2017年于深圳证券交易所中小企业板上市，成为国内A股童装第一股。经过二十多年的创业创新，安奈儿现在已经成为消费者最喜欢的童装品牌之一。'
			}, {
				type: 'cg',
				title: '千叶',
				img: require('../static/images/case/qyzb.png'),
				desc: '　　KEER千叶珠宝致力于设计美丽时尚的珠宝，分享爱与美。甄选上乘宝石，精湛工艺，悉心雕琢。自2001年成立以来...',
				info: '　　KEER千叶珠宝致力于设计美丽时尚的珠宝，分享爱与美。甄选上乘宝石，精湛工艺，悉心雕琢。自2001年成立以来，千叶珠宝一直致力于透过精致、时尚的产品，为女性消费者带来超越珠宝本身的生命愉悦感受，荣获了“亚洲品牌500强”、“中国企业成长百强”等荣誉称号。'
			}, {
				type: 'cg',
				title: '曼卡龙',
				img: require('../static/images/case/mankal.png'),
				desc: '　　MCLON曼卡龙珠宝诞生于1994年，秉承专业的经营理念和服务精神，以不断追求创新设计为品牌核心。作为业界...',
				info: '　　MCLON曼卡龙珠宝诞生于1994年，秉承专业的经营理念和服务精神，以不断追求创新设计为品牌核心。作为业界为数不多的掌握稀缺珠宝原料资源的专业公司，分别在意大利的佛罗伦萨和中国的香港设立了珠宝设计研发中心，致力于为消费者提供紧跟时尚潮流，又极具设计内涵的珠宝饰品。'
			}, {
				type: 'cg',
				title: '康奈',
				img: require('../static/images/case/kangnai.png'),
				desc: '　　康奈集团有限公司创办于1980年，是中国皮鞋行业的排头兵企业。公司总部坐落于温州，先后获得中国名牌产品...',
				info: '　　康奈集团有限公司创办于1980年，是中国皮鞋行业的排头兵企业。公司总部坐落于温州，先后获得中国名牌产品、中国出口名牌、中国真皮领先鞋王和全国质量奖、浙江省政府质量奖等500多项荣誉，营销网络遍布全球，在全国有专卖店（柜）3000多家，其中海外专卖店（柜）100多家。'
			}, {
				type: 'cg',
				title: '鸿星尔克',
				img: require('../static/images/case/hxek.png'),
				desc: '　　公司成立于2000年6月，总部位于厦门，经过多年励精图治，目前已发展为集研发、生产、销售为一体的大型服饰...',
				info: '　　公司成立于2000年6月，总部位于厦门，经过多年励精图治，目前已发展为集研发、生产、销售为一体的大型服饰企业。公司在全世界拥有店铺7000余家，产品行销欧洲、东南亚、中东、南北美洲、非洲等国家和地区，并相继斩获“中国500最具价值品牌”、“亚洲品牌500强”、“《福布斯》亚洲200佳”等殊荣。'
			}, {
				type: 'cg',
				title: '明牌',
				img: require('../static/images/case/mpzb.png'),
				desc: '　　明牌珠宝，中国最大的珠宝企业之一，全球领先铂金首饰生产商与零售商，行业工艺标准制定者之一。自1987年...',
				info: '　　明牌珠宝，中国最大的珠宝企业之一，全球领先铂金首饰生产商与零售商，行业工艺标准制定者之一。自1987年创建以来，明牌珠宝立足于先人一步的全球整合实力和雄厚企业背景，现在已发展成为集研发、设计、生产、销售于一体的大型专业珠宝企业，涵盖钻石、黄金、K金在内的全品类珠宝。'
			}, {
				type: 'cg',
				title: '方所',
				img: require('../static/images/case/fangsuo.png'),
				desc: '　　方所由中国服装协会副会长，文化创意界知名企业家毛继鸿于2011年一手创办。方所不仅是一家书店，更是一个...',
				info: '　　方所由中国服装协会副会长，文化创意界知名企业家毛继鸿于2011年一手创办。方所不仅是一家书店，更是一个文化平台，一种未来的生活形态。它是一家集书店、美学生活、咖啡、展览空间与服饰时尚等混业经营为一体的集成店。'
			}, {
				type: 'cg',
				title: 'DKNY',
				img: require('../static/images/case/dkny.png'),
				desc: '　　DKNY（唐可娜儿）创立于1989年，发源于美国纽约。是一个前卫、时尚、休闲的时装品牌，产品包括男女装成衣...',
				info: '　　DKNY（唐可娜儿）创立于1989年，发源于美国纽约。是一个前卫、时尚、休闲的时装品牌，产品包括男女装成衣，鞋履，包袋配饰，内衣，腕表，及香氛等。以更前卫、更时尚、更休闲的手法描绘了纽约不同文化、不同生活方式的时代气息。'
			}, {
				type: 'cg',
				title: 'DISSONA',
				img: require('../static/images/case/diss.png'),
				desc: '　　DISSONA，是一个具有国际视野，展现都市女性优雅形象的高阶轻奢手袋鞋履品牌，为中国消费者的时尚生活...',
				info: '　　DISSONA，是一个具有国际视野，展现都市女性优雅形象的高阶轻奢手袋鞋履品牌，为中国消费者的时尚生活找到全新原创能量。其产品从都会时尚和当代艺术中汲取灵感，将时尚设计融入都市生活，塑造出都市女性自信、优雅、品味主张的生活方式。'
			}, {
				type: 'cg',
				title: 'lOOK眼镜',
				img: require('../static/images/case/look.png'),
				desc: '　　LOOK眼镜创始于2013年，隶属于森马服饰和韩国SISUN时装集团合资企业——上海盛夏服饰有限公司。秉承“打...',
				info: '　　LOOK眼镜创始于2013年，隶属于森马服饰和韩国SISUN时装集团合资企业——上海盛夏服饰有限公司。秉承“打破既有概念，使眼镜转变为一种潮流配饰”理念，针对不同人群进行产品开发，关注细节、注重用户感受，鼓励客户尝试不同风格的眼镜搭配，彰显多样的形象魅力。'
			}]
		}
	},
	created() {
		this.caseListon = this.allcaseList
	},
	mounted() {
		this.selectId = localStorage.getItem("toId");
		if(this.selectId) {
			this.jump(this.selectId)
			setTimeout(() => {
				localStorage.setItem("toId",'')
			}, 500);
		} else {
			console.log('wu')
		}
	},
	methods: {
		jump(index) {
			let jump = document.getElementsByClassName('section')
			// 获取需要滚动的距离
			let total = jump[index].offsetTop - 150
			// console.log(total)
			$('html, body').animate({ scrollTop: total }, { duration: 500,easing: "swing" })
			return false
		},
		handleNum() {
			this.maxNum = 100
			this.showNum = false
		},
		changeActive($event, _index){
			this.indexnum = _index
			$event.currentTarget.className="active case-con"
		},
		removeActive($event){
			this.indexnum = 'all',
			$event.currentTarget.className="case-con"
		},
		handleTab(_type) {
			this.type = _type
			this.caseListon = []
			if(_type === 'ca') {
				this.caseListon = this.allcaseList
				return false
			}
			this.caseList.forEach((res) => {
				if(_type === res.type) {
					this.caseListon.push(res)
				}
			})
		},
		handleFoot(e) {
			this.jump(e)
		}
	},
	destroyed(){
		localStorage.setItem("toId",'')
	},
	filters: {
		ellipsis (value) {
			if (!value) return ''
			if (value.length > 72) {
				return value.slice(0,72) + '...'
			}
			return value
		}
	}
}
</script>
<style>
.more-div{
	width:136px;
	height:46px;
	border:1px solid rgba(2,62,153,1);
	margin: 0 auto;
	font-size:14px;
	line-height: 44px;
	text-align: center;
	margin-bottom: 30px;
	color: #023E99;
	background: #FFFFFF;
	transition: all 0.4s;
}
.more-div:hover{
	background: #023E99;
	color: #FFFFFF;
	cursor: pointer;
}
.case-con-text{
	width: 100%;
	height: 130px;
	padding: 18px 17px 0 12px;
	overflow: hidden;
	box-sizing: border-box;
	border-left: 1px solid #E1E6EE;
	border-right: 1px solid #E1E6EE;
	border-bottom: 1px solid #E1E6EE;
	position: absolute;
	bottom: 0;
	left: 0;
	transition: all 0.3s;
	-moz-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	background: #ffffff;
}
.active .case-con-text{
	height: 300px;
	border-top: 1px solid #E1E6EE;
}
.case-con-text .p01{
	color: #1C2E4A;
	font-size: 16px;
	margin-bottom: 12px;
	font-weight: bold;
}
.case-con-text .p02{
	color: #60708F;
	font-size: 14px;
	word-break: break-all;
	line-height: 22px;
	/* height: 60px; */
	/* overflow: hidden;
	transition: height 0.5s;
	-moz-transition: height 0.5s;
	-webkit-transition: height 0.5s;
	-o-transition: height 0.5s; */
}
/* .active .case-con-text .p02{
	height: 100px;
} */
.case-con-img{
	width: 285px;
	height: 170px;
	display: block;
}
.case-con{
	width:285px;
	height:300px;
	float: left;
	box-sizing: border-box;
	margin-bottom: 21px;
	margin-right: 20px;
	position: relative;
}
.case-con:nth-child(4n){
	margin-right: 0
}
.case-con-div{
	width: 100%;
	height: auto;
	overflow: hidden;
	padding-bottom: 20px;
}
.tab-case-box{
	width: 100%;
	text-align: center;
	padding: 40px 0 0;
	border-bottom: 1px solid #E1E6EE;
	margin-bottom: 29px;
	line-height: 34px;
	height: 34px;
}
.tab-case-ul{
	display: inline-block;
	margin: 0 auto;
	list-style:none
}
.tab-case-ul li{
	color: #60708F;
	font-size: 14px;
	line-height: 34px;
	padding: 0 15px;
	float: left;
	list-style:none;
	position: relative;
	cursor: pointer;
}
.tab-case-ul .on,.tab-case-ul li:hover{
	color: #023E99;
}
.tab-case-ul .on:before,.tab-case-ul li:hover:before{
	position: absolute;
	bottom: -1px;
	left: 50%;
	width: 28px;
	height: 2px;
	margin-left: -14px;
	background-color: #023E99;
	display: block;
	content: "";
}
.con-l-b{
	width: 100%;
	height: 32px;
	padding-left: 26px;
	box-sizing: border-box;
	margin-top: 12px;
}
.con-l-b img{
	float: left;
}
.con-l-b p{
	line-height: 32px;
	padding: 0 9px;
	float: left;
	color: #1C2E4A;
	font-size: 14px;
}
.con-l-b .p-line{
	margin-right: 17px;
	padding-right: 17px;
}
.con-l-b .p-line::before{
	position: absolute;
	width: 1px;
	height: 20px;
	content: "";
	display: block;
	background: #D3DEF6;
	top: 6px;
	right: 0;
}
.con-l-a{
	width: 100%;
	height: 272px;
}
.con-r-a{
	width: 100%;
	height: 330px;
	box-sizing: border-box;
	padding-left: 30px;
	padding-top: 22px;
}
.con-l-bg{
	background-image: url(../static/images/bkl.jpg);
	background-position: right center;
	background-repeat: no-repeat;
	margin-bottom: 30px;
}
.con-r-bg{
	background-image: url(../static/images/bkr.jpg);
	background-position: right bottom;
	background-repeat: no-repeat;
	margin-bottom: 30px;
}
.con-l-a .p01, .con-r-a .p01{
	color: #E3E8F2;
	font-size: 50px;
	line-height: 60px;
}
.con-l-a .p02, .con-r-a .p02{
	color: #8E9FC0;
	font-size: 14px;
	padding-left: 26px;
	margin-bottom: 5px;
}
.con-l-a .p03, .con-r-a .p03{
	padding-left: 26px;
	color: #1C2E4A;
	font-size: 14px;
	width: 540px;
	box-sizing: border-box;
}
.con-r-a .p03{
	width: 560px;
}
.solution-a{
	padding-top: 70px;
}
.solution-acon{
	width: 100%;
	height: 800px;
	box-sizing: border-box;
	padding-top: 60px;
}
.solution-acon .con-l{
	width: 560px;
	float: left;
	margin-right: 20px;
}
.solution-acon .con-r{
	width: 620px;
	float: left;
}
.solution-banner .p01{
	font-size: 36px;
	color: #FFFFFF;
	position: relative;
	z-index: 1;
	padding-top: 170px;
	line-height: 60px;
}
.solution-banner .p02{
	font-size: 20px;
	line-height: 50px;
	color: #fff;
	position: relative;
	z-index: 1;
	opacity: .8;
}
.solution-title{
	font-size: 22px;
	color: #1C2E4A;
	position: relative;
	line-height: 30px;
	height: 30px;
	padding-left: 42px;
	box-sizing: border-box;
	width: 140px;
	margin: 0 auto;
}
.solution-title:before{
	content: "";
	width: 19px;
	height: 5px;
	background: #023E99;
	position: absolute;
	top: 8px;
	left: 0;
	border-radius:5px;
	display: block;
}
.solution-title:after{
	content: "";
	width: 17px;
	height: 5px;
	background: #C2D7F6;
	position: absolute;
	top: 17px;
	left: 10px;
	border-radius:5px;
	display: block;
}
</style>
